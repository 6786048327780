<template>
  <div class="form__footer row">
    <div class="form__buttons col-xs-11 col-md-10 offset-xs-1 offset-md-2">
      <div class="form__buttons__left">
        <router-link :to="cancelUrl" class="btn btn-outline-gray btn-arrow-back btn-sm form__footer__btn">{{ $t('cancel') }}</router-link>
      </div>
      <div class="form__buttons__right">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cancelUrl: Object
  }
}
</script>
