import { Dates } from '@/_helpers/dates'

const NO_FEEDBACK = ''
const POSTAL_CODE_REGEX = /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/

export default {
  required(value, invalidFeedback) {
    return this.test(value || value === false, invalidFeedback)
  },

  postalCode(value, invalidFeedback) {
    return this.test(POSTAL_CODE_REGEX.test(value), invalidFeedback)
  },

  optionalDateIsBeforeOrEqual(first, second, invalidFeedback) {
    return this.test(!first || !second || Dates.isBeforeOrEqual(first, second), invalidFeedback)
  },

  optionalDateIsBetweenInclusive(value, start, end, invalidFeedback) {
    return this.optionalDateIsBeforeOrEqual(start, value, invalidFeedback) || this.optionalDateIsBeforeOrEqual(value, end, invalidFeedback)
  },

  optionalDateIsAfterOrEqual(first, second, invalidFeedback) {
    return this.test(!first || !second || !Dates.isBefore(first, second), invalidFeedback)
  },

  test(pass, invalidFeedback) {
    return pass ? NO_FEEDBACK : invalidFeedback
  },

  NO_FEEDBACK
}

