<template>
  <div class="form__header row" :class="{ 'form__header--sticky': isSticky }">
    <div class="form__header__back col-xs-12 col-md-2 offset-xs-1 offset-md-0">
      <router-link :to="cancelUrl" class="form__header__back__link">{{ $t('cancel') }}</router-link>
    </div>

    <div class="form__header__main col-xs offset-xs-1 offset-md-0">
      <h1 class="form__header__title">{{ title }}</h1>
    </div>

    <div class="form__header__utils col-xs-auto">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    cancelUrl: Object,
  },
  data() {
    return {
      isSticky: false
    }
  },
  methods: {
    handleScroll() {
      this.isSticky = window.pageYOffset > 40
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
