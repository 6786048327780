<template>
  <div>
    <form-gcr data-test-id="form" ref="form" :title="$t('buildingSchedule')" :cancel-url="projectDetailUrl" @submit.prevent>
      <template #buttons>
        <button-gcr
          data-test-id="submit-button"
          btn-class="btn-primary btn-sm form__header__btn"
          label="buildingScheduleSubmitUpdate"
          type="button"
          :disabled="isReadOnly"
          :loading="submitting"
          @btnclick="submit(updateBuildingSchedule)"
        />
      </template>
      <div class="form__body row">
        <div class="form__container col-xs-10 col-sm-8 col-md-6 offset-xs-1 offset-md-2">
          <div class="row">
            <div class="col form__col">
              <h2 data-test-id="building-address">{{ buildingAddress }}</h2>
              <div data-test-id="info-line" v-html="$t('buildingConstructionScheduleInfo')"></div>
            </div>
          </div>
          <building-planned-dates data-test-id="building-planned-dates" :planned-dates="plannedDates" />
        </div>
      </div>
    </form-gcr>
  </div>
</template>

<script>
import getBuildingConstructionSchedule from '@/graphql/accreditations/projects/getBuildingConstructionSchedule.graphql'
import updateBuildingConstructionSchedule from '@/graphql/accreditations/projects/updateBuildingConstructionSchedule.graphql'
import BuildingPlannedDates from '@/components/building/edit/construction-site/BuildingPlannedDates'
import { Consts, Urls } from '@/_helpers'
import { address } from '@/_helpers/address'
import { mapGetters } from 'vuex'
import ButtonGcr from '@/components/atoms/Button'
import FormGcr from '@/components/molecules/Form'
import form from '@/components/mixins/form'
import alerts from '@/_helpers/alerts'

export default {
  components: { FormGcr, ButtonGcr, BuildingPlannedDates },
  mixins: [form],
  props: {
    projectId: String,
    buildingId: String
  },
  data() {
    return {
      plannedDates: {},
      submitting: false,
      projectDetailUrl: {
        name: Urls.PROJECT_DETAILS,
        query: {
          id: this.projectId
        }
      },
      buildingAddress: null
    }
  },
  computed: {
    ...mapGetters({
      accreditationId: Consts.GETTER_ACCREDITATION_CURRENT_ID,
      isReadOnly: Consts.GETTER_USER_ISSAC
    })
  },
  apollo: {
    getBuildingConstructionSchedule: {
      query: getBuildingConstructionSchedule,
      variables() {
        return {
          accreditationId: this.accreditationId,
          projectId: this.projectId,
          buildingId: this.buildingId
        }
      },
      update({ accreditation: { project } }) {
        this.plannedDates = { ...project.building.plannedDates }
        this.buildingAddress = address({ ...project.building, city: project.city })
      }
    }
  },
  methods: {
    async updateBuildingSchedule() {
      await this.update()
      this.dirty = false
      alerts.showInfo('buildingConstructionScheduleUpdateSuccess')
      return this.$router.push(this.projectDetailUrl)
    },
    update() {
      return this.$apollo.mutate({
        mutation: updateBuildingConstructionSchedule,
        variables: {
          input: {
            accreditationId: this.accreditationId,
            projectId: this.projectId,
            buildingId: this.buildingId,
            start: this.plannedDates.start,
            foundationBackfilling: this.plannedDates.foundationBackfilling,
            wallCoverings: this.plannedDates.wallCoverings,
            insideWallCoverings: this.plannedDates.insideWallCoverings,
            end: this.plannedDates.end
          }
        }
      })
    },
    checkValidity() {
      return this.$refs.form.$el.checkValidity()
    },
    setValidated() {
      this.$refs.form.$el.classList.add('was-validated')
    },
    async submit(action) {
      try {
        this.submitting = true
        this.setValidated()
        if (this.checkValidity())
          await action()
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>